import styled from 'styled-components';

export const Container = styled.div`
  ${({ styles, buttonClicked }) => {
    switch (styles) {
      case 'pdp2023':
        return `
        & {
          position:relative;
        }

        &:before {
          content: " ";
          display: block;
          width: calc(100% + 16px);
          height: 80px;
          margin-top: -8px;
          margin-left: -8px;
          background: transparent;
          border-radius: 80px;
          border: 8px solid transparent;
          position: absolute;
          transition: border 800ms;
        }

        &.pressed:before {
          border: 8px solid #F5CCDD;
        }

          & > .processing {
            border-radius: 80px!important;
            z-index: 1;
            position: absolute;
            top: 0;
            width: 100%;
            box-shadow: none!important;
            height: 64px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        `;
      case 'default':
      default:
        return ``;
    }
  }}
`;

export const Button = styled.div`
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  &.processing {
    &:before {
      content: '';
      height: 20px;
      width: 100%;
      background-image: url('/images/three-dots.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      margin: auto;
    }
    & > div {
      display: none;
    }

  ${({ overrideColors, backgroundColor }) => {
    const bgColor =
      backgroundColor && backgroundColor !== '' ? backgroundColor : '#e26447';
    return overrideColors ? `background-color: ${bgColor};` : ``;
  }}
  ${({ overrideColors, textColor }) => {
    const txtColor = textColor && textColor !== '' ? textColor : '#FFF';
    return overrideColors ? `color: ${txtColor};` : ``;
  }}
  ${({ overrideColors, backgroundColorHover }) => {
    const bgHover =
      backgroundColorHover && backgroundColorHover !== ''
        ? backgroundColorHover
        : '#a54e32';
    return overrideColors
      ? `
      box-shadow: 0 4px 0 ${bgHover};
      `
      : ``;
  }}
}
  
`;
