import React, { useState, useEffect, useContext } from 'react';
import * as S from './SubmitLocalCart.styles';
import useLocalStorage from '../../hooks/useLocalStorage';
import { submitLocalCart } from '../../utils/submitLocalCart';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import useButtonEventPressed from '../../hooks/useButtonEventPressed';

const AddToCartButton = props => {
  const {
    children,
    styles,
    backgroundColor,
    backgroundColorHover,
    overrideColors
  } = props;
  const [buttonClicked, setButtonClicked] = useState(false);
  const [localCart] = useLocalStorage('local-cart');
  const { extraObjects, setExtraObjects } = useContext(FunnelContext);

  useEffect(() => {
    if (!extraObjects?.localCart) {
      setExtraObjects(
        Object.assign(extraObjects, {
          localCart: localCart
        })
      );
    }
  }, [extraObjects, setExtraObjects, localCart]);

  useButtonEventPressed('submit-to-local-trigger');

  const handleClick = async () => {
    if (Builder.isEditing) return;
    if (buttonClicked) return;
    setButtonClicked(true);

    await submitLocalCart(extraObjects.localCart).then(({ redirect }) => {
      setButtonClicked(false);
      window.location.href = redirect;
    });
  };

  return (
    <S.Container
      styles={styles}
      buttonClicked={buttonClicked}
      className="submit-to-local-trigger"
    >
      <S.Button
        onClick={handleClick}
        className={buttonClicked ? `processing` : ``}
        disabled={buttonClicked}
        backgroundColor={backgroundColor}
        backgroundColorHover={backgroundColorHover}
        overrideColors={overrideColors}
      >
        {children}
      </S.Button>
    </S.Container>
  );
};

export default AddToCartButton;
